import { useAsyncCallback } from '@orus.eu/pharaoh'
import { useEffect, useState } from 'react'
import { trpc } from '../../client'
import { sessionManager } from '../session'
import { useNavigateTo } from './use-navigate-to-route'

// await logout first to clean the session and redirect only after that, otherwise login page will redirect to the app
export const useDisconnect = (): (() => void) => {
  const [isLoggedOut, setIsLoggedOut] = useState(false)
  const navigateToLoginPage = useNavigateTo({ to: '/login' })

  useEffect(() => {
    if (isLoggedOut) {
      navigateToLoginPage()
    }
  }, [isLoggedOut, navigateToLoginPage])

  const execLogout = useAsyncCallback(async () => {
    await trpc.sessions.logout.mutate(undefined)
    await sessionManager.refreshSession()
    setIsLoggedOut(true)
  }, [])

  return execLogout
}
